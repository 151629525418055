<template>
	<div class="detail flex-space-between" style="margin-bottom: 20px">
		<div class="left content">
			<div class="header flex-direction-column">
				<span>{{ pageInfo.name }}</span>
				<p>{{ pageInfo.create_time }}</p>
			</div>
			<video :src="pageInfo.url" :poster="pageInfo.image" loop controls></video>
			<div class="source" v-if="pageInfo.source">（来源：{{ pageInfo.source }}）</div>
			<!--			<div class="article flex-direction-column">-->
			<!--				<span @click="choiceClick(pageInfo.last.id)" v-if="pageInfo.last">上一篇：{{ pageInfo.last.name }}</span>-->
			<!--				<span @click="choiceClick(pageInfo.next.id)" v-if="pageInfo.next">下一篇：{{ pageInfo.next.name }}</span>-->
			<!--			</div>-->
		</div>
		<!--		<div class="right">-->
		<!--			<div class="title title-vertical flex-space-between">-->
		<!--				<span>相关视频</span>-->
		<!--			</div>-->
		<!--			<div class="r_content flex-direction-column">-->
		<!--				<div class="r_item flex-space-between" @click="relevantClick(item.id)" v-for="item in list" :key="item.id">-->
		<!--					<img :src="item.image"/>-->
		<!--					<div class="desc flex-direction-column">-->
		<!--						<span class="two-line">{{ item.name }}</span>-->
		<!--						<p>{{ item.create_time }}</p>-->
		<!--					</div>-->
		<!--				</div>-->
		<!--			</div>-->
		<!--		</div>-->
	</div>
</template>

<script>
import {postVideoDetail, postVideoRecommend} from '@/service/api/index'

export default {
	data() {
		return {
			pageInfo: {},
			list: []
		}
	},

	mounted() {
		window.addEventListener('beforeunload', this.beforeunloadFn())
		if (this.$route.params.info) {
			let info = this.$route.params.info
			sessionStorage.setItem('navbarId', info.id)
			this.postVideoDetail(info.id)
		}
		this.postVideoRecommend()
	},
	destroyed() {
		window.removeEventListener('beforeunload', this.beforeunloadFn())
	},

	methods: {
		postVideoDetail(id) {
			postVideoDetail({id}).then((res) => {
				this.pageInfo = Object.freeze(res.msg)
			})
		},
		postVideoRecommend() {
			postVideoRecommend().then((res) => {
				this.list = res.msg
			})
		},
		beforeunloadFn() {
			if (!this.$route.params.info) {
				let navbarId = sessionStorage.getItem('navbarId')
				this.postVideoDetail(navbarId)
			}
		},
		choiceClick(id) {
			this.postVideoDetail(id)
		},
		relevantClick(id) {
			this.postVideoDetail(id)
		}
	}
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/pictureANDvideo.less');
</style>
